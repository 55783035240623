import React from "react";
import { FaHandshake } from "react-icons/fa";
import "../styles/Contact.css";

const Contact = () => {
  return (
    <div
      name="contact"
      id="contact"
      className="h-full text-[#07325f] bg-[#fff] flex justify-center items-center px-4 py-8"
    >
      <form
        method="POST"
        action="https://getform.io/f/c0eee403-8edf-4661-a9d2-8f3ae29e2c7a"
        className="flex flex-col max-w-[600px] w-full h-full"
      >
        <div className="pb-4">
          <p className="text-4xl font-bold inline border-b-4 border-[#efea5a] text-[]">
            Contact
          </p>
          <p className=" py-4 leading-7">
            Submit the form below to get in touch or alternatively send me an
            email at:
            <span className="text-[#b85000] font-bold">
              <a href="mailto:stefan.medves@gmail.com">
                {" "}
                stefan.medves@gmail.com
              </a>
            </span>
          </p>
        </div>
          <input
            className="text-[#07325f] bg-slate-100 p-2"
            type="text"
            placeholder="Name"
            name="name"
            required
          />
          <input
            className="my-4 p-2 text-[#07325f] bg-slate-100"
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <textarea
            className="text-[#07325f] bg-slate-100 p-2"
            name="message"
            rows="10"
            placeholder="Message"
            required
          ></textarea>
        <button className="text-[#07325f] font-semibold border-2 border-[#07325f] mb-4 hover:bg-[#efea5a] hover:border-[#efea5a] hover:text-[#07325f] px-4 py-3 my-8 mx-auto flex items-center">
          Work with me
          <FaHandshake className="ml-2" />
        </button>
      </form>
    </div>
  );
};

export default Contact;
