import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  FaBars,
  FaTimes,
  
} from "react-icons/fa";
import Logo from "../assets/sm1.png";

const Navbar = () => {
  let navLinks = [
    { name: "home" },
    { name: "about" },
    { name: "projects" },
    { name: "contact" },
  ];
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed top-0 left-0 w-full h-[80px] flex justify-between items-center pr-4 bg-black text-[#ffffff]">
      <div className="cursor-pointer">
        <Link to="home" smooth={true}>
          <img
            src={Logo}
            alt=""
            style={{ width: "250px", height: "80px" }}
          />
        </Link>
      </div>
      <div
        onClick={handleClick}
        className="text-2xl absolute right-6 top-5 cursor-pointer md:hidden"
      >
        {nav ? (
          <FaTimes className="text-[#b85000]" />
        ) : (
          <FaBars className="text-[#efea5a]" />
        )}
      </div>
      <nav className="stroke">
        <ul
          className={`md:flex md:items-center md:pb-0 bg-black pb-2 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            nav ? "pt-4 " : "top-[-490px]"
          }`}
        >
          {navLinks.map((link) => (
            <li key={link.name} className="text-[#ccd6f6] text-xl md:my-0 my-7 capitalize">
              <Link to={link.name} smooth={true}  onClick={handleClick}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
