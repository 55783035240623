import Quiz from "../assets/quiz.avif";
import Walking from "../assets/walking2.avif";
import Pawfect from "../assets/pawfect.avif";
import Makeup from "../assets/makeup.avif";
import Moovers from "../assets/moovers.avif";

export const ProjectList = [
  {
    name: "JavaScript Quiz",
    image: `${Quiz}`,
    skills: "JavaScript, API, HTML, CSS",
    description:
      "This Quiz game was created using Open Trivia Database API. The project itself is focused on user interaction and was build using HTML, CSS and JavaScript",
    demo: "https://stefanmdvs.github.io/quiz-codeInstitute/index.html",
    code: "https://github.com/StefanMdvs/quiz-codeInstitute",
  },
  {
    name: "Kids love walking",
    image: `${Walking}`,
    skills: "Python, Flask, JavaScript, HTML, CSS",
    description:
      "Full-stack project using Python with Jinja template. Users can perform CRUD functionalities and the database makes use of MongoDb platform.",
    demo: "https://kids-love-walking.onrender.com/",
    code: "https://github.com/StefanMdvs/kids-love-walking",
  },
  {
    name: "Pawfect",
    image: `${Pawfect}`,
    skills: "Python, Django, PostgreSQL, Stripe, JavaScript, HTML, CSS",
    description:
      "This is a full stack e-commerce website with CRUD operations, Stripe payment infrastructure and authentication feature.",
    demo: "https://pawfect.onrender.com/",
    code: "https://github.com/StefanMdvs/pawfect",
  },
  {
    name: "Makeup Artist",
    image: `${Makeup}`,
    skills: "React, Tailwind CSS, HTML",
    description:
      "This project was created for a family member and it is ready to go live as soon as their personal circumstances will allow them to take on the business. It is a one page website where I explored React and used Tailwind CSS for styling.",
    demo: "https://makeup-artist.onrender.com/#home",
    code: "https://github.com/StefanMdvs/bianca",
  },
  {
    name: "Moovers",
    image: `${Moovers}`,
    skills: "HTML, JavaScript, Tailwind CSS",
    description:
      "This project was created as part of January Hackathon held by Code Institute and got the 3rd place. It simulated real life working environment and I had the chance of learning about pull requests and merging conflicts. The project calls an API that checks the cheepest flight from a specific airport.",
    demo: "https://lexach91.github.io/team9-Jan2023-CI-hackathon/index.html",
    code: "https://github.com/lexach91/team9-Jan2023-CI-hackathon",
  },
];
