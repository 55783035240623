import React from "react";
import { FiGithub } from "react-icons/fi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsCloudDownload } from "react-icons/bs";
import Resume from "../assets/Resume.pdf";

const Footer = () => {
  return (
    <div className="h-full w-full text-[#ccd6f6]">
      <div className="grid grid-cols-1 sm:grid-cols-2 items-center justify-items-center bg-black py-4">
        {/* social column */}
        <div className="items-center">
          <div className="text-center">
            <h3>Follow me on:</h3>
          </div>
          <div className="flex">
            <div>
              <a
                href="https://github.com/StefanMdvs"
                target="_blank"
                rel="noreferrer"
                name="Github"
                aria-label="Github"
              >
                <FiGithub className="m-4 text-2xl text-[#efea5a] hover:text-[#ccd6f6]" />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/in/stefan-medves/"
                target="_blank"
                rel="noreferrer"
                name="LinkedIn"
                aria-label="LinkedIn"
              >
                <AiOutlineLinkedin className="m-4 text-2xl text-[#efea5a] hover:text-[#ccd6f6] " />
              </a>
            </div>
          </div>
        </div>
        {/* Resume column */}
        <div className="self-start justify-center text-center">
          <div>
            <h3>Download my Resume:</h3>
          </div>
          <div className="justify-center">
            <a href={Resume} className=" text-lg center" download="StefanMedves_Resume.pdf" rel="noreferrer" name="Download Resume" aria-label="Download Resume">
              <BsCloudDownload className="m-4 text-2xl text-[#efea5a] hover:text-white" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-sm text-center grid grid-cols-1 gap-2 sm:grid-cols-2 bg-black border-t-[1px] border-[#ccd6f6] py-2">
        <div>
          <p className="">
            &copy;{new Date().getFullYear()} Stefan Medves. All Rights reserved.
          </p>
        </div>
        <div>
          <a
            href="https://www.flaticon.com/free-icons/software-engineer"
            title="software engineer icons"
          >
            Software engineer icons created by Freepik - Flaticon.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
