import React from "react";
import Resume from "../assets/Resume.pdf";
import engineer from "../assets/pictures/engineer4.avif";

const Home = () => {
  return (
    <div
      id="home"
      className="bg-black h-full mt-10 pt-10 w-full"
    >
      {/* container */}
      <div className=" max-h-full w-full grid grid-cols-1 md:grid-cols-2 gap-0">
        {/* h-80 md:h-96 */}
        <div className=" w-full px-4 md:px-8 md:pt-8">
          {/* holds name, description and button*/}
          <p className="text-[#efea5a] font-bold">Hi, my name is</p>
          <h1 className="py-4 text-2xl md:text-4xl font-bold text-[#ccd6f6]">
            Stefan Medves
          </h1>
          <h2 className="md:py-2 leading-7 text-[#A1B3EE]">
            Full stack developer focused on building responsive and reliable web
            applications.{" "}
          </h2>
          <div className="text-left py-4">
            <button className="text-[#efea5a] tracking-wider font-bold group px-3 py-3 my-2 border-[#efea5a] flex justify-center items-center border-2 hover:cursor-pointer hover:bg-[#1f1f1f] hover:border-[#efea5a] hover:text-[#efea5a]">
              <a href={Resume} download="StefanMedves_Resume.pdf">
                Download my CV
              </a>
            </button>
          </div>
        </div>
        <div className="w-full h-full ">
          <div className="h-full flex justify-center bg-[#f56a00]">
            <img src={engineer} alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
