import React from "react";
import { Tooltip } from "flowbite-react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FiGithub } from "react-icons/fi";
import { ProjectList } from "../helpers/ProjectList";

const Projects = ({ id }) => {
  // const navigate = useNavigate(id);
  return (
    <div
      name="projects"
      id="projects"
      className="w-full h-full bg-[#07325f] text-[#CCD6F6] pb-8"
    >
      <div className="max-w-screen-lg mx-auto pt-8 flex flex-col justify-center w-full h-full">
        <div className="pb-4 pl-4">
          <p className="text-4xl font-bold inline border-b-4 border-[#efea5a]">
            Projects
          </p>
          <p className="py-6">Have a look at some of my projects</p>
        </div>

        {/* grid container for projects */}
        {ProjectList.map((project, idx) => {
          return (
            <div className="bg-[#b85000] text-[#ffffff] grid grid-cols-1 md:grid-cols-2 gap-4 py-4 md:py-8 " key={idx}>
              <div
                className={
                  (idx % 2 === 0) | (window.innerWidth <= "768")
                    ? "order-2 text-left px-4"
                    : "px-4"
                }
              >
                <p className="text-2xl font-semibold text-[#efea5a]">{project.name}</p>
                <p className="pt-4 leading-7 text-[#f2f3f4]">{project.description}</p>
                <p className="pt-4 italic">Stack: {project.skills}</p>
                <p className="flex pt-8 text-[#efea5a]">
                  <span>
                    <Tooltip
                      content="Deployed project"
                      animation="duration-500"
                      style="light"
                    >
                      <a href={project.demo} target="_blank" rel="noreferrer" name="Deployed project" aria-label="Deployed project">
                        <FaExternalLinkAlt />
                      </a>
                    </Tooltip>
                  </span>
                  <span className="px-4">
                    <Tooltip
                      content="GitHub"
                      animation="duration-500"
                      style="light"
                    >
                      <a href={project.code} target="_blank" rel="noreferrer" name="Github" aria-label="Github repository">
                        <FiGithub />
                      </a>
                    </Tooltip>
                  </span>
                </p>
              </div>
              <div>
                <img src={project.image} alt="" loading="lazy" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
