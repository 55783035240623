import React from "react";

const About = () => {
  return (
    <div name="about" id="about" className="h-full w-full text-[#353238]">
      <div className="flex flex-col justify-center items-center w-full h-full pt-8">
        <div className="max-w-screen-lg w-full grid grid-cols-1 gap-8">
          <div className="pb-8 pl-4">
            <p className="text-4xl text-[#07325f] font-bold inline border-b-4 border-[#efea5a]">
              About me
            </p>
          </div>
        </div>
        <div className="max-w-screen-lg w-full grid grid-cols-1 px-4">
          <div className="text-[#07325f]">
            <p>
              My coding journey began in 2019 when I started learning the very
              basics of HTML and CSS, following a series of free online
              resources.
            </p>
            <p>
              Recently I graduated with a{" "}
              <span className="font-bold text-[#f56a00]">
                Diploma in Software Development
              </span>{" "}
              from Code Institute.
            </p>
            <p className="py-4">
              Here are the technologies I've been working with during my
              Diploma:
            </p>
            <ul className="tech grid grid-cols-2">
              <li className="px-0 sm:px-4">HTML</li>
              <li className="">CSS</li>
              <li className="px-0 sm:px-4">JavaScript</li>
              <li className="">Python</li>
              <li className="px-0 sm:px-4">Django</li>
              <li className="">Flask</li>
              <li className="px-0 sm:px-4">MongoDB</li>
              <li className="">PostgreSQL</li>
            </ul>
          </div>
          <div className="py-4">
            <p>Recently I dived into learning:</p>
            <ul className="tech grid grid-cols-2 pt-4">
              <li className="px-0 sm:px-4">React</li>
              <li className="">TailwindCss</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
